import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["defaultSubmit", "channelSubmit"]

  connect() {
    document.addEventListener("channel-mention-added", this.showChannelConfirm.bind(this))
    document.addEventListener("trix-attachment-remove", this.hideChannelConfirm.bind(this))
  }

  showChannelConfirm(e) {
    this.channelSubmitTarget.dataset.confirm = `This will notify ${e.detail.count} people. Are you sure?`
    this.defaultSubmitTarget.classList.add('hidden')
    this.channelSubmitTarget.classList.remove('hidden')
  }

  hideChannelConfirm(e) {
    if (e.attachment.attachment.attributes.values.is_channel === undefined) return;
    this.defaultSubmitTarget.classList.remove('hidden')
    this.channelSubmitTarget.classList.add('hidden')
  }
}
